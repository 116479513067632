.viewer-box {
    background: #ffffff;
    border-radius: 5px;
    padding: 15px;
}

.header__logo {
    max-height: 60px;
    max-width: 260px;
}
